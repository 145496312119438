.join-container {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.join-l {
  flex: 1 1;
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.join-l > div {
  display: flex;
  gap: 1rem;
}

.join-l > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px;
  left: 10px;
}

.join-r {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.form-class {
  display: flex;
  gap: 4rem;
  background-color: rgb(242, 155, 84);
  padding: 1rem 2rem;
}

.btn-j {
  /* background-color: var(--orange); */
  /* color: #fff; */
}

.form-class > input {
  border: none;
  outline: none;
  background: rgb(242, 155, 84);
  color: var(--lightgray);
}

::placeholder {
  color: var(--lightgray);
}

@media screen and (max-width: 768px) {
  .join-container {
    flex-direction: column;
  }

  .join-l > div:nth-of-type(1) {
    display: flex;
    font-size: 2.5rem;
    flex-direction: column;
    gap: 0.5rem;
  }
  .join-l > :nth-of-type(2) {
    display: flex;
    font-size: 2.5rem;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-class {
    transform: scale(0.8);
  }

  .btn-j {
    width: 4rem;
  }
}
