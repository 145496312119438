.footer-container {
  position: relative;
  padding: 5rem 0rem;
}

.footer-container > hr {
  border: 2px solid var(--lightgray);
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 2rem;
  justify-content: space-evenly;
}

.social-imgs {
  display: flex;
  gap: 3.5rem;
  justify-content: center;
}

.social-imgs > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.f-logo {
  width: 97vw;
  display: flex;
  justify-content: center;
}

.f-logo > img {
  width: 10rem;
}

.footer-blur-1 {
  width: 20rem;
  height: 15rem;
  background: rgb(255, 55, 0);
  filter: blur(200px);
  bottom: 0;
  left: 25%;
}

.footer-blur-2 {
  width: 20rem;
  height: 12rem;
  bottom: 0;
  right: 25%;
}
