.plans-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 2rem;
}

.plans-card {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
}

.single-plan {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: var(--caloryCard);
  padding: 2rem;
  width: fit-content;
  justify-content: center;
}

.join-btn {
  display: flex;
  justify-content: center;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature {
  display: flex;
  gap: 1rem;
}

.single-plan > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: var(--orange);
}

.single-plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.single-plan > :nth-child(3) {
  font-size: 2.8rem;
  font-weight: bold;
  color: #fff;
}

.feature > img {
  width: 1rem;
  height: 1rem;
}

.feature > :nth-child(2) {
  font-size: 1rem;
  color: #fff;
}

.more-benefits {
  color: #fff;
  font-size: 0.9rem;
}

.single-plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}

.single-plan:nth-child(2) > :nth-child(1) {
  fill: #fff;
}

.single-plan:nth-child(2) > .join-btn > .btn {
  color: var(--orange);
}

.plans-blur-1 {
  width: 20rem;
  height: 20rem;
  left: 0;
  top: 6;
}

.plans-blur-2 {
  width: 20rem;
  height: 20rem;
  right: 0;
  top: 9;
}

@media screen and (max-width: 768px) {
  .plans-card {
    display: flex;
    flex-direction: column;
  }

  .single-plan:nth-child(2) {
    transform: scale(1);
  }
}
