.Reasons {
  display: flex;
  padding: 0 2rem;
  gap: 2rem;
}

.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem; /* Constant gap of 1rem */
}

.left-r > img {
  object-fit: cover;
  width: 100%; /* Ensure images take full width of their grid cell */
  height: auto; /* Adjust height to maintain aspect ratio */
}

.left-r > :nth-child(1) {
  grid-row: 1 / 3;
  height: 28rem;
}

.left-r > :nth-child(2) {
  grid-column: 2 / 4;
  height: 16rem;
}

.left-r > :nth-child(3) {
  grid-row: 2;
  grid-column: 2 / 3;
  height: 10.5rem;
}

.left-r > :nth-child(4) {
  grid-row: 2;
  grid-column: 3 / 4;
  height: 10.5rem;
}

.right-r {
  flex: 1 1;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.right-r > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}

.right-r > div {
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.details-r > div {
  display: flex;
  gap: 1rem;
}

.details-r > div > img {
  width: 2rem;
  height: 2rem;
}

.details-r > div > span {
  font-size: 1.2rem;
}

.partners {
  display: flex;
  gap: 1.3rem;
}

.partners > img {
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 768px) {
  .Reasons {
    flex-direction: column;
  }

  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  }

  .left-r > :nth-child(1) {
    width: 7rem;
    height: 17rem;
  }

  .left-r > :nth-child(2) {
    width: 15rem;
    height: 10rem;
  }

  .left-r > :nth-child(3) {
    width: 8rem;
    height: 6rem;
  }
  .left-r > :nth-child(4) {
    width: 7rem;
    height: 4rem;
  }
}
