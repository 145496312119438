.testimonials-container {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.left-t {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-transform: uppercase;
  position: relative;
}

.left-t > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}

.left-t > :nth-child(2),
.left-t > :nth-child(3) {
  font-size: 2.8rem;
  color: #fff;
  font-weight: bold;
}

.left-t > :nth-child(4) {
  color: #fff;
  text-transform: none;
  line-height: 2rem;
  letter-spacing: 1.5px;
  text-align: justify;
}

.left-t > :nth-child(5) > :nth-child(1) {
  color: var(--orange);
}

.left-t > :nth-child(5) > :nth-child(2) {
  color: #fff;
}

.right-t {
  flex: 1;
  position: relative;
}

.right-t > img {
  position: absolute;
  object-fit: cover;
  width: 17rem;
  height: 20rem;
  top: 2rem;
  right: 12rem;
}

.right-t > :nth-child(1) {
  height: 20rem;
  width: 17rem;
  border: 3px solid var(--orange);
  position: absolute;
  top: 1rem;
  left: 14rem;
}

.right-t > :nth-child(2) {
  background: var(--planCard);
  width: 17rem;
  height: 19rem;
  position: absolute;
  top: 4.5rem;
  right: 10rem;
}

.arrows {
  display: flex;
  gap: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .testimonials-container {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .left-t > span:nth-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-t > span:nth-of-type(2) {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-t > span:nth-of-type(3) {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-t > span:nth-of-type(4) {
    line-height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-t {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-t > img:nth-of-type(1) {
    position: relative;
    left: 0;
  }

  .arrows {
    position: absolute;
    left: 6rem;
    top: 24rem;
  }
}
